function getHeader(headers: Record<string, string>, header: string) {
  return (
    headers?.[header] ||
    headers?.[header.toLowerCase()] ||
    headers?.[header.toUpperCase()]
  );
}

let imageProcessingCachedHost = '';

export default function (useForImageProcessing = false) {
  if (imageProcessingCachedHost && useForImageProcessing) {
    return imageProcessingCachedHost;
  }

  const nuxtApp = useNuxtApp();
  let host = '';
  if (process.server) {
    let originalHost = '';
    const contextHeaders = nuxtApp.ssrContext?.event.node.req.headers;
    const headers = typeof req !== 'undefined' ? req?.headers : contextHeaders;
    if (headers) {
      const newHost =
        getHeader(headers, 'X-Original-Hostname') ||
        getHeader(headers, 'http-x-forwared-host');
      if (newHost) {
        originalHost = `https://${newHost}/`;
      }
    }
    host = nuxtApp.ssrContext?.event.node.req.headers.host;
    if (host && originalHost && host !== originalHost) {
      console.warn('Host changed from', originalHost, 'to', host);
    } else if (!host) {
      console.warn('Host not found', originalHost);
    }
    host = originalHost || host;
  } else {
    host = window.location.host;
  }

  if (!host.startsWith('http')) {
    host = host.includes('localhost') ? `http://${host}` : `https://${host}`;
  }

  host = host.endsWith('/') ? host.slice(0, -1) : host;

  if (useForImageProcessing) {
    imageProcessingCachedHost = host.includes('pfsrealty.')
      ? host
      : 'https://pfsrealty.com';

    return imageProcessingCachedHost;
  }

  return host;
}
